import { type Ref, shallowRef } from "vue";

export type Query<T> =
  | {
      status: "uninitialized";
    }
  | {
      status: "pending";
      abortController?: AbortController;
    }
  | {
      status: "ready";
      data: T;
    }
  | {
      status: "error";
      error: unknown;
    };

const useQuery = <T>(
  fn: () => Promise<T>,
  onSuccess?: () => void,
  onError?: (err: unknown) => void
): {
  data: Ref<Query<T>>;
  execute: () => Promise<void>;
} => {
  const data = shallowRef<Query<T>>({
    status: "uninitialized",
  });

  const execute = async () => {
    data.value = {
      status: "pending",
    };
    try {
      const result = await fn();
      data.value = {
        status: "ready",
        data: result,
      };
      onSuccess?.();
    } catch (e) {
      data.value = {
        status: "error",
        error: e,
      };
      onError?.(e);
    }
  };

  return {
    data,
    execute,
  };
};

export default useQuery;
